@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.details {
  background-color: $background-detail;
  $body-gap: 10px;
  padding-bottom: 48px;
  position: relative;

  .container {
    margin: 0 auto;
    display: grid;
    column-gap: 109px;
    max-width: 906px;
    row-gap: 20px;

    @include media(sm) {
      margin-top: 40px;
    }

    @include media(md) {
      column-gap: 48px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media(lg) {
      max-width: 1036px;
      grid-template-columns: minmax(0, 1fr) minmax(0, 440px);
    }

    @include media(xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 480px);
    }

    @include media(2xl) {
      max-width: 1222px;
      column-gap: 88px;
    }

    @include media(3xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 623px);
      max-width: 1369px;
    }
  }

  .details__body {
    background: $detail-background-product-info;
    border-radius: $detail-border-radius-product-info;

    @media screen and (min-width: 420px) {
      padding: 20px;
    }

    @include media(md) {
      padding: 30px;
    }

    @include media(2xl) {
      padding: 48px;
    }

    p {
      color: $gray-600;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .flower__type {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $product-category-border-radius;
      font-size: $text-xs;
      font-weight: 700;
      width: fit-content;
      height: 26px !important;
      line-height: 14px;
      padding: 6px 12px;
      text-transform: uppercase;
      //letter-spacing: 0.09px;
    }

    $flower_types: (
      'default' $flower-type-default-bg-color $flower-type-default-color,
      'sativa' $flower-type-sativa-bg-color $flower-type-sativa-color,
      'indica' $flower-type-indica-bg-color $flower-type-indica-color,
      'hybrid' $flower-type-hybrid-bg-color $flower-type-hybrid-color,
      'vape' $flower-type-vape-bg-color $flower-type-vape-color,
      'extract' $flower-type-extract-bg-color $flower-type-extract-color,
      'si' $flower-type-si-bg-color $flower-type-si-color,
      'is' $flower-type-is-bg-color $flower-type-is-color
    );

    @each $name, $bg, $color in $flower_types {
      .type__#{$name} {
        border: 1px solid $color !important;
        //background-color: $bg !important;
        color: $color !important;
      }
    }
  }

  .details__images {
    overflow: hidden;
    text-align: center;
    position: relative;
    padding: 0 15px;
    margin-bottom: 20px;

    @include media(md) {
      top: 184px;
      position: sticky;
      overflow: unset;
      align-self: flex-start;
      position: -webkit-sticky;
      margin-bottom: 64px;
    }

    .slick-arrow {
      display: block;
      color: $controls-slider;
      font-size: 0;
      line-height: 0;
      background: rgba(255, 255, 255, 0.45);
      border: 0;
      position: absolute;
      z-index: 2;
    }

    .slick-disabled {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 0.4;
    }

    .slick-prev {
      left: -28px;
      top: 40%;
      border-radius: 0;

      @media screen and (min-width: 500px) {
        top: 200px;
      }

      @include media(md) {
        top: 131px;
        left: -25px;
      }

      @include media(lg) {
        top: 113px;
      }

      @include media(xl) {
        top: 35%;
      }

      @include media(2xl) {
        top: 38%;
      }
    }

    .slick-prev::after {
      border-right: 0;
    }

    .slick-next {
      right: -28px;
      top: 40%;
      border-radius: 0;

      @media screen and (min-width: 500px) {
        top: 200px;
      }

      @include media(md) {
        top: 131px;
        right: -25px;
      }

      @include media(lg) {
        top: 113px;
      }

      @include media(xl) {
        top: 35%;
      }

      @include media(2xl) {
        top: 38%;
      }
    }

    .slick-thumb {
      position: relative;
      display: none !important;
      flex-wrap: wrap;
      gap: 18px;
      margin-top: 24px;

      @media screen and (min-width: 500px) {
        display: flex !important;
        justify-content: center;
      }

      @include media(md) {
        display: none !important;
      }

      @include media(lg) {
        gap: 20px;
        display: flex !important;
      }

      figure {
        width: 70px;
        height: 70px;

        @include media(xl) {
          width: 90px;
          height: 90px;
        }
      }

      img {
        background: $detail-background-img;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
        border-radius: $border-radius;
      }

      li[class='slick-active'] {
        border: 1px solid $btn-actions-product;
        border-radius: $border-radius;
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
        //box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        /*  box-shadow: rgba(11, 59, 74, 0.1) 0px 4px 16px,
                        rgba(11, 59, 74, 0.1) 0px 8px 24px,
                        rgba(11, 59, 74, 0.1) 0px 16px 56px; */
      }

      li {
        cursor: pointer;
      }
    }

    .slide_main__load {
      img {
        border-radius: $detail-border-radius-product-info;
        background: $detail-placeholder-color;
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      }
    }

    .slide_main {
      position: relative;
      z-index: 1;
      width: 100%;
      aspect-ratio: 1/1;
      max-width: 458px;
      border-radius: $border-radius;

      @include media(sm) {
        padding: 0 5px;
      }

      @include media(2xl) {
        max-width: 654px;
      }

      .product__in_stock {
        border-radius: 20px;
        border: 1px solid $detail-stock-border-color;
        background: $detail-stock-bg-color;
        color: $detail-stock-text-color;
        font-size: $text-xs;
        padding: 4px 20px;
        position: absolute;
        left: 50%;
        bottom: 25px;
        transform: translate(-50%, 0);

        @include media(xl) {
          font-size: $text-sm;
          //padding: 8px 36px;
        }
      }

      /*   img {
        background: $background-detail;
      } */
    }
  }

  .product__badges {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem;
    gap: 6px;

    .badge {
      height: 24px;
      margin-right: auto;
    }

    .product__sale {
      background: $badge !important;
      border: 0.5px solid $badge-border-color;
      color: $badge-text !important;
    }

    .product__stock {
      background: $badge-warning;
      border: 0.5px solid $badge-warning-border-color;
      color: $badge-text-warning !important;
    }
  }

  .details__product_heading {
    font-size: calc(1.325rem + 0.9vw);
    font-weight: 700;
    line-height: calc(1.725rem + 0.9vw);
    position: relative;

    @include media(xl) {
      font-size: 36px;
      line-height: 40px;
    }
  }

  small {
    display: block;
    font-size: $text-sm;
    font-weight: 400;
    line-height: 18px;
    color: $gray-300;
  }

  .details__span {
    font-weight: 400;
    font-size: $text-base;
    line-height: 24px;
    color: $gray-500;
    text-transform: uppercase;
  }

  .details__feats {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    .acceptance__level {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 19px;

      .rating__card {
        justify-content: space-between;
        > i {
          font-size: $text-xl;
          margin-right: 10px;
          color: $gray-200;
          width: 17.11px;
          height: 16.36px;
        }
      }

      i[class~='icon_star'] {
        color: $rating-color;
      }

      i[class~='icon_star-half-alt'] {
        color: $rating-color;
      }

      .reviews {
        font-weight: 400;
        font-size: $text-lg;
        line-height: 22px;
        color: $gray-300 !important;
        margin-left: 8px;
      }
    }

    .content__flex {
      display: flex;
      gap: 8px;

      .description {
        font-weight: 500;
        font-size: $text-base;
        line-height: 24px;
        color: $detail-label-props;
        padding-right: 20px;
      }

      .icon {
        font-size: 22px;
        color: $detail-icon-props;
      }
    }

    .highlighted {
      font-weight: 400;
      color: $detail-label-props;
    }
  }

  .lab_results {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include media(md) {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 340px);
      gap: 48px;
    }

    @include media(lg) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 440px);
    }

    @include media(xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 546px);
      padding-right: 32px;
    }

    @include media(2xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 584px);
      gap: 88px;
    }

    @include media(3xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 908px);
    }

    .terpenes {
      .terpene_list {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        @include media(2xl) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        .terpene_item {
          display: flex;
          align-items: center;
          padding-block: 4px;
          width: 100%;
          gap: 12px;

          figure {
            margin-top: 12px;
            align-self: flex-start;
          }

          svg {
            transform: scale(1);
            margin-top: 0;

            * {
              stroke: $detail-lab-results-icon-color;
            }
          }

          .terpene_info {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .terpene_header {
              display: flex;
              justify-content: space-between;

              .terpene_name {
                font-weight: 500;
                font-size: $text-base;
                color: $detail-lab-results-title-text-color;
              }

              .terpene_percentage {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                padding: 3px 8px 2px;
                color: $detail-lab-results-badge-text-color;
                background-color: $detail-lab-results-badge-background-color;
                border-radius: 100px;
                font-weight: 700;
              }
            }

            .terpene_description {
              font-size: 14px;
              color: $detail-lab-results-description-text-color;

              .terpene_aroma {
                font-weight: 700;
              }
            }
          }

          &:first-child {
            .terpene_info {
              .terpene_header {
                .terpene_percentage {
                  background-color: $detail-lab-results-active-badge-background-color;
                }
              }
            }
          }
        }
      }
    }

    .cannabinoids {
      @include media(md) {
        padding-left: 32px;
      }
      @include media(2xl) {
        padding-left: 48px;
      }

      &__container {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include media(lg) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 32px;
        }

        @include media(xl) {
          column-gap: 46px;
        }

        @include media(3xl) {
          column-gap: 110px;
        }

        .cannabinoid_compound_section {
          padding: 0 4px 0 36px;
          width: 100%;

          @media screen and (max-width: 420px) {
            padding: 0 4px 0 8px;
          }

          @include media(md) {
            padding: 0;
          }

          .list_title {
            margin-bottom: 12px;
            font-size: 16px;
            font-weight: 700;
            color: $detail-lab-results-cannabinoids-label-text-color;
          }

          .cannabinoid_list {
            display: flex;
            gap: 12px;
            flex-direction: column;
            justify-content: space-between;

            .cannabinoid_item {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              color: $detail-lab-results-cannabinoids-value-text-color;
              font-size: 14px;

              .cannabinoid_name {
                color: $detail-lab-results-cannabinoids-label-text-color;
              }
            }
          }
        }
      }

      .single_element {
        .cannabinoid_compound_section {
          .cannabinoid_list {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
            row-gap: 12px;
            column-gap: 88px;
          }
        }
      }
    }
  }

  .one_element {
    @include media(md) {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      gap: 48px;
    }

    .cannabinoids {
      padding-left: 0px;

      &__container {
        @include media(md) {
          gap: 48px;
        }

        @include media(2xl) {
          gap: 88px;
        }

        .cannabinoid_compound_section {
          .cannabinoid_list {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
            row-gap: 12px;
            column-gap: 88px;
          }
        }
      }
    }

    .terpenes {
      .terpene_list {
        @include media(md) {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
          row-gap: 12px;
          column-gap: 88px;
        }
      }
    }
  }

  .details__category_title,
  .details__h3 {
    font-weight: 700;
    font-size: $text-xl;
    line-height: 24px;
    color: $gray-600;
  }

  .details__h3 {
    margin: 24px 0 20px;
  }

  .details__category_title {
    color: $detail-link-category !important;

    a {
      background-image: linear-gradient(
        to right,
        $detail-link-category 50%,
        transparent 50%
      );
    }
  }

  .details__description {
    color: $gray-500;
    font-weight: 400;
    margin-bottom: 32px;
    font-size: $text-base;
  }

  .details__subtitle {
    font-weight: 500;
    font-size: $text-base;
    margin-bottom: 10px;
  }

  .extra_details__subtitle {
    margin: 0 !important;
  }

  .extra_details__badge {
    height: auto;
    padding: 8px 12px;
    border: 1px solid $detail-props-badge-border-color;
    background-color: $detail-props-badge-bg;
    border-radius: $border-radius-md;

    i {
      margin-right: 8px;
      font-size: 18px;
    }

    span {
      padding-top: 0.5px;
    }
  }

  .bottom__warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    padding-bottom: 20px;
    color: $detail-warning-btn;

    i {
      margin-left: 10px;
      font-size: 24px;
      color: $btn-actions-product;
    }
  }

  svg {
    transform: scale(0.5);
    margin-top: -5px;
  }

  .accordion__content {
    display: flex;
    gap: 5px;

    &_collapse {
      margin-bottom: 24px;
    }
  }

  .warning__content {
    color: $detail-warning-text;

    span,
    p,
    a {
      color: $detail-warning-text !important;
      font-weight: 600;
    }

    a {
      &:hover {
        color: $label-btn-link-hove !important;
      }
    }

    :not(a) {
      margin-right: 5px;
    }
  }

  .extra__content {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    span {
      color: $detail-props-badge-text-color !important;
      font-weight: 600;
    }
  }

  .sharing {
    margin-bottom: 32px;
    margin-left: 6px !important;
    border: 0.5px solid $detail-sharing-border-color;
    color: $detail-sharing-icon;
  }

  .details__availability {
    display: flex;
    align-items: center;

    svg {
      transform: scale(1);
      margin-top: 2px;
    }

    &_message {
      display: flex;
      flex-direction: column;

      &__actions {
        display: flex;
        flex-direction: column;
        margin-top: 4px;

        .location_button {
          color: $alert-text-warning-color;
          font-size: $text-xs;
          line-height: 14px;
          font-weight: 500;
          text-decoration: underline;
          text-underline-offset: 2.1px;
          padding: 0 2px;
          height: 18px;
          width: fit-content;
        }
      }
    }

    &.hidden {
      display: none;
    }
  }

  .details__item-control {
    display: none;

    @include media(sm) {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 3;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      gap: 16px;
      padding: 24px 0px;
      margin-bottom: 32px;
      background: inherit;
      border-top: 0.5px solid $detail-divider;
      border-bottom: 0.5px solid $detail-divider;
    }

    .details__item {
      position: relative;
      width: -webkit-fill-available;

      &.hidden {
        display: none;
      }

      .button_button_color-primary__Aqnc2 {
        color: $detail-counter-label-btn;
        background: $detail-counter-btn;
        border: 1px solid $detail-counter-border-btn;

        &:not(:disabled):hover {
          color: $detail-counter-label-btn-hover;
          background: $detail-counter-btn-hover;
          border: 1px solid $detail-counter-border-btn-hover;
        }

        &:not(:disabled):focus {
          color: $detail-counter-label-btn-pressed;
          background: $detail-counter-btn-pressed;
          border: 1px solid $detail-counter-border-btn-pressed;
        }
      }

      &-link {
        background-image: none;
        display: flex;
        align-items: center;
        justify-content: right;
        text-transform: uppercase;
        column-gap: 6px;
        font-weight: 600;
        padding: 0 5px;
        min-width: 256px;
        width: fit-content;
        margin-left: auto;
        color: $label-btn-link;

        svg {
          width: 24px;
          height: 24px;
          transform: none;
          margin: 0;
          * {
            stroke: $label-btn-link;
          }
        }

        &:hover,
        &:focus {
          color: $label-btn-link-hove;
          svg {
            * {
              stroke: $label-btn-link-hove;
            }
          }
        }
      }
    }

    .availability_placeholders {
      display: flex;
      width: 100%;
      gap: 1rem;

      &.hidden {
        margin: 0;
        display: none;
      }

      .placeholder {
        width: 100%;
        flex-grow: 1;
        height: 40px;
        animation: pulse 5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        background: $detail-placeholder-color;
      }
    }

    .counter {
      .product__in-cart {
        position: absolute;
        right: -9px;
        top: -10px;
        background: $errors;
        font-size: $text-sm;
        width: 26px;
        height: 26px;
      }
    }

    .detail__product_actions {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: space-between;
      align-items: center;
      height: auto;
      gap: 16px;

      p {
        flex-grow: 1;
        width: fit-content;
        margin-bottom: 0px;
        text-align: left;
      }

      .favorite_icon {
        width: 48px !important;
        padding: 0 !important;
        font-size: $text-2xl;
        cursor: pointer;
        text-align: center;

        @include media(xl) {
          width: 56px !important;
        }
      }
    }
  }

  .details__item-about {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
    order: 1;

    @include media(lg) {
      flex-direction: row;
      flex: 0 1 50%;
      margin-bottom: 24px;
    }
  }

  .details__item-column {
    width: 100%;
    display: block;

    p,
    div {
      margin-bottom: 16px;
    }

    div {
      color: $gray-600;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }

    strong {
      font-weight: 400;
      color: $gray-400;
      margin-right: 5px;
      flex-shrink: 0;
    }

    div:last-child {
      margin-bottom: 0;
    }
  }

  .details__payments-card {
    display: flex;
    flex-direction: row;
    column-gap: 8px;

    svg {
      width: 29.17px;
      height: 20px;

      &:first-child {
        margin-left: 16px;
      }
    }
  }

  .details__data_product {
    width: 100%;

    > :nth-of-type(1) {
      order: 2;
    }

    > :nth-of-type(2) {
      order: 1;
    }
  }

  .details__rating-reviews {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    border-radius: 0 0 $border-radius-md $border-radius-md;
    column-gap: 2.5rem;
    row-gap: 2.5rem;

    @include media(sm) {
      row-gap: 0;
      flex-direction: row;
    }

    @include media(md) {
      border-radius: $border-radius-md 0 $border-radius-md $border-radius-md;
    }

    .rating_reviews__filters_container {
      display: flex;
      flex: 1;
      flex-direction: column;
      row-gap: 2.5rem;

      @include media(md) {
        row-gap: 0;
      }

      @include media(2xl) {
        flex-direction: row;
      }

      .rating_reviews__filters {
        display: flex;
        flex-direction: column;
        flex: 1;

        @include media(sm) {
          min-width: 350px;
        }

        .filter_header {
          font-size: $text-lg;
          line-height: 1.5rem;
          margin-bottom: 1.5rem;
          color: $gray-600;

          @include media(sm) {
            margin-top: 4rem;
          }
        }

        .filter_list {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;

          .filter_list__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 1.5rem;
            border: 1px solid $states-btn-border-color;
            color: $states-btn-text-color;
            background-color: $states-btn-bg-color;
            border-radius: $rating-btn-border-radius;
            font-size: $text-sm;
            width: 100%;
            cursor: pointer;

            @include media(md) {
              width: auto;
              justify-content: baseline;
            }

            &.active {
              border: 1px solid $states-btn-active-border-color;
              background-color: $states-btn-active-bg-color;
              color: $states-btn-active-text-color;

              .item_icon {
                color: $states-btn-active-text-color;
              }
            }

            &:not(.active) {
              &:hover {
                border: 1px solid $states-btn-hover-border-color;
                background-color: $states-btn-hover-bg-color;
                color: $states-btn-hover-text-color;

                .item_icon {
                  color: $states-btn-hover-text-color;
                }
              }
            }

            .item_icon {
              font-size: $text-xl;
              margin-left: 1.5rem;
            }
          }
        }
      }
    }
  }

  .rating__component {
    max-width: 496px;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    margin-right: auto;
  }

  .details__tab-reviews {
    position: relative;
    display: table-row;
  }

  a {
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 4px 1px;
    background-image: linear-gradient(
      to right,
      rgb(135, 135, 135) 50%,
      transparent 50%
    );

    &:hover {
      color: $label-btn-link-hove;
      background-image: linear-gradient(
        to right,
        $label-btn-link-hove 50%,
        transparent 50%
      );
    }
  }
}

.main__details {
  margin-bottom: 36px;

  @include media(sm) {
    margin-bottom: 64px;
  }
}

.placeholder__page_product_detail {
  padding-top: 48px;
  padding-bottom: 48px;
  position: relative;

  .placeholder__product_detail {
    display: grid;
    row-gap: 40px;
    margin: 40px 0;
    max-width: 906px;
    margin: 0 auto;

    @include media(sm) {
      margin-top: 40px;
    }

    @include media(md) {
      column-gap: 40px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media(lg) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 400px);
    }

    @include media(xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 480px);
      max-width: 1036px;
    }

    @include media(2xl) {
      max-width: 1222px;
      column-gap: 88px;
    }

    @include media(3xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 623px);
      max-width: 1369px;
      column-gap: 160px;
    }

    .placeholder__product_asset {
      .placeholder__product_asset_container {
        @include media(lg) {
          display: grid;
          grid-template-columns: 1fr;
        }
      }

      .placeholder__dot_container {
        margin-top: 24px;
        display: flex;
        column-gap: 18px;
        justify-content: center;

        .placeholder__figure {
          max-width: 50px;
          padding: 5px;

          @include media(xl) {
            max-width: 90px;
          }
        }
      }
    }

    .placeholder__product_info {
      padding: 30px 20px;
      border-radius: $detail-border-radius-product-info;
      background-color: $detail-background-product-info;

      @include media(md) {
        padding: 50px 40px;
      }

      .placeholder__product_brand {
        height: 12px;
        width: 40%;
        margin-bottom: 5px;
      }

      .placeholder__product_name {
        width: 100%;
        height: 60px;
        margin-bottom: 16px;
      }

      .placeholder__product_category {
        width: 25%;
        height: 24px;
        margin-bottom: 16px;
      }

      .placeholder__product_description_title {
        height: 20px;
        width: 55%;
        margin-bottom: 10px;
        background-color: $placeholder-bg-100;
      }

      .placeholder__product_social_title {
        @extend .placeholder__product_description_title;
        width: 20%;
      }

      .placeholder__social_item {
        width: 30px;
        height: 30px;
        border-radius: 30%;
      }

      .placeholder__text {
        height: 12px;
        width: 100%;
        margin-bottom: 3px;
      }

      .placeholder__product_actions {
        display: flex;
        align-items: center;
        column-gap: 24px;
        padding: 25px 10px;
        border-top: 1px solid $gray-50;
        border-bottom: 1px solid $gray-50;
        margin-bottom: 30px;

        .placeholder__btn {
          height: 57px;
          max-width: 170px;
          flex-grow: 1;
          border-radius: 30px;
        }

        .placeholder__price {
          height: 38px;
          width: 40%;
          max-width: 80px;
          background-color: #e3eff1;
        }

        .placeholder__favorite {
          height: 45px;
          min-width: 45px;
          max-width: 45px;
          border-radius: 50%;
        }
      }

      .placeholder__product_about_title {
        @extend .placeholder__product_description_title;
        width: 30%;
      }

      .placeholder__product_about {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
    }

    .placeholder__text:nth-last-of-type(6) {
      width: 80%;
    }

    .placeholder__text:nth-last-of-type(5) {
      width: 92%;
    }

    .placeholder__text:nth-last-of-type(4) {
      width: 96%;
    }

    .placeholder__text:nth-last-of-type(3) {
      width: 87%;
    }

    .placeholder__text:nth-last-of-type(2) {
      width: 67%;
    }

    .placeholder__text:nth-last-of-type(1) {
      width: 43%;
      margin-bottom: 30px;
    }

    .placeholder__figure,
    .placeholder__figure_secondary {
      aspect-ratio: 1/1;
      padding: 20px;
      width: 100%;
      height: 100%;
      background: $placeholder-bg;
    }

    .placeholder__figure_secondary {
      display: none;

      @include media(lg) {
        display: inline-block;
      }
    }
  }

  .placeholder {
    position: relative;
    width: 100%;
    max-width: 536px;
    height: 100%;
  }

  .placeholder__products_related {
    .placeholder__related_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      .placeholder__related_title {
        width: 60%;
        max-width: 320px;
        height: 52px;
      }

      .placeholder__related_actions {
        width: 80px;
        height: 40px;
        border-radius: 30px;
      }
    }

    .placeholder__related_container {
      display: flex;
      overflow: hidden;
    }
  }

  .placeholder__product_rating,
  .placeholder__product_social {
    display: flex;
    column-gap: 16px;
    margin-bottom: 50px;
  }

  .placeholder__start_small {
    width: 20px;
    height: 20px;
    border-radius: 30%;
    background-color: #e3eff1;
  }
}

.product__detail-control_modal {
  $space-x: 40px;
  $form-width: 446px;
  z-index: 3 !important;
  background: $background-theme !important;
  box-shadow: 0px -4px 24px 8px #070d151a;

  @media screen and (min-width: 520px) {
    display: none;
  }

  > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    cursor: pointer !important;
    padding: 30px 20px 20px !important;
  }

  .portal_drawer__content__DQxQ3 {
    @media screen and (max-width: 420px) {
      padding: 40px 20px 0px 20px !important;
    }
  }

  .details__item-control {
    .details__item {
      position: relative;
      width: -webkit-fill-available;

      .button_button_color-primary__Aqnc2 {
        color: $detail-counter-label-btn;
        background: $detail-counter-btn;
        border: 1px solid $detail-counter-border-btn;

        &:not(:disabled):hover {
          color: $detail-counter-label-btn-hover;
          background: $detail-counter-btn-hover;
          border: 1px solid $detail-counter-border-btn-hover;
        }

        &:not(:disabled):focus {
          color: $detail-counter-label-btn-pressed;
          background: $detail-counter-btn-pressed;
          border: 1px solid $detail-counter-border-btn-pressed;
        }
      }

      &-link {
        background-image: none;
        display: flex;
        align-items: center;
        justify-content: right;
        text-transform: uppercase;
        column-gap: 6px;
        font-weight: 600;
        font-size: $text-sm;
        line-height: 1rem;
        padding: 0 5px;
        min-width: 256px;
        width: fit-content;
        margin-left: auto;
        color: $label-btn-link;

        svg {
          width: 24px;
          height: 24px;
          transform: none;
          margin: 0;
          * {
            stroke: $label-btn-link;
          }
        }

        &:hover,
        &:focus {
          color: $label-btn-link-hove;
          svg {
            * {
              stroke: $label-btn-link-hove;
            }
          }
        }
      }
    }

    .availability_placeholders {
      display: flex;
      width: 100%;
      gap: 1rem;

      &.hidden {
        margin: 0;
        display: none;
      }

      .placeholder {
        width: 100%;
        flex-grow: 1;
        height: 40px;
        animation: pulse 5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        background: $detail-placeholder-color;
      }
    }

    .details__availability {
      display: flex;
      align-items: center;

      svg {
        transform: scale(1);
        margin-top: 2px;
      }

      &_message {
        display: flex;
        flex-direction: column;

        &__actions {
          display: flex;
          flex-direction: column;
          margin-top: 4px;

          .location_button {
            color: $alert-text-warning-color;
            font-size: $text-xs;
            line-height: 14px;
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: 2.1px;
            padding: 0 2px;
            height: 18px;
            width: fit-content;
          }
        }
      }

      &.hidden {
        display: none;
      }
    }

    .counter {
      .product__in-cart {
        position: absolute;
        right: -9px;
        top: -10px;
        background: $errors;
        font-size: $text-sm;
        width: 26px;
        height: 26px;
      }
    }

    .detail__product_actions {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: space-between;
      will-change: transform;
      overflow: hidden;
      //justify-self: flex-start !important;
      align-self: center;
      align-items: center;
      height: 48px;
      gap: 16px;

      @include media(xl) {
        height: 56px;
      }

      p {
        flex-grow: 1;
        width: fit-content;
        margin-bottom: 0px;
        text-align: left;
        align-self: center;
      }

      .favorite_icon {
        width: 48px !important;
        flex-shrink: 0;
        padding: 0 !important;
        font-size: $text-2xl;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
